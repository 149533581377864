import React from "react"
import { Container, Box } from "@mui/material"
import { makeStyles } from "makeStyles"
import Button from "components/Buttons/Button"
import Link from "components/i18n/Link"

import SEO from "@secureo/common/components/SEO"
import Illustration from "svg/404-illustration.svg"

const useStyles = makeStyles()({
	row: {
		display: "flex",
		flexFlow: "row wrap",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	leftItem: {
		flexBasis: "48%",
		width: "100%",
		position: "relative",
		padding: "10px",
		boxSizing: "border-box",
		textAlign: "center",
	},
	rightItem: {
		flexBasis: "48%",
		width: "100%",
		position: "relative",
		padding: "10px",
		boxSizing: "border-box",
	},
	button: {
		maxWidth: 150,
	},
	svg: {
		width: "100%",
		maxHeight: 400,
		paddingTop: 20,
		margin: "0",
		objectFit: "contain",
		position: "relative",
	},
	categoryLinks: {
		display: "flex",
		alignItems: "center",
	},
	categoryButton: {
		width: "auto",
		border: "1px solid rgba(115, 122, 128, 0.4)",
		borderRadius: 20,
		paddingLeft: 15,
		paddingRight: 15,
		textTransform: "inherit",
		background: "white",
		transition: "0.3s",
		color: "#505e5f",
		marginRight: 10,
		marginBottom: 10,
		"&:hover": {
			backgound: "#E7EFF6",
		},
	},
	"@media only screen and (max-width: 960px)": {
		categoryLinks: {
			justifyContent: "center",
			flexWrap: "wrap",
		},
		svg: {
			width: "50%",
		},
		leftItem: {
			marginRight: 30,
			flexBasis: "100%",
		},
		rightItem: {
			textAlign: "center",
			flexBasis: "100%",
		},
		button: {
			maxWidth: 150,
			margin: "auto",
		},
	},
})

const categoryLinks = [
	{
		title: "E-Türschlösser",
		link: "/c/elektronische-tuerschloesser",
	},
	{
		title: "Schließzylinder",
		link: "/c/schliesszylinder",
	},
	{
		title: "Schlüsselverwaltung",
		link: "/c/schluesselmanagement",
	},
]

// test test test test

const NotFoundPage = () => {
	const { classes } = useStyles()

	return (
		<>
			<SEO asPath="" appendLocalizedShopNameWithPipe title="404" />
			<Box my={5} pb={10}>
				<Container>
					<div className={classes.row}>
						<div className={classes.leftItem}>
							<Illustration className={classes.svg} />
						</div>
						<div className={classes.rightItem}>
							<div
								style={{
									fontSize: 100,
									color: "#505E5F",
									fontWeight: 800,
									marginTop: -10,
									marginBottom: 0,
									height: "auto",
								}}
							>
								404
							</div>
							<div
								style={{
									textTransform: "uppercase",
									fontSize: "1.2rem",
									color: "#505E5F",
									fontWeight: 800,
								}}
							>
								Entschuldigung
							</div>
							<p>
								Leider ist ein Fehler aufgetreten, die gewünschte Seite konnte nicht
								gefunden werden.
							</p>
							<p>
								Bitte benutzen Sie unsere Suchfunktion oder durchstöbern Sie einfach
								unsere Kategorien.
							</p>
							<div className={classes.categoryLinks}>
								{categoryLinks.map((categoryLink) => {
									const { title, link } = categoryLink
									return (
										<Link href={link} key={title}>
											<Button className={classes.categoryButton}>
												<div
													style={{
														whiteSpace: "nowrap",
														fontSize: "0.9rem",
													}}
												>
													{title}
												</div>
											</Button>
										</Link>
									)
								})}
							</div>
							<p>
								Oder vielleicht finden Sie den von Ihnen gewünschten Inhalt über die
								Starseite.{" "}
							</p>
							<Box mt={4} className={classes.button}>
								<Link href="/">
									<Button color="secondary" size="small">
										Zur Startseite
									</Button>
								</Link>
							</Box>
						</div>
					</div>
				</Container>
			</Box>
		</>
	)
}

export default NotFoundPage
